<template>
  <mx-select
    ref="refSelect"
    v-model="currentValue"
    :disabled="loading || !areaId"
    placeholder="请选择"
    :style="{ width }"
  >
    <mx-option
      v-for="item in options"
      :key="item['id']"
      :label="item['name']"
      :value="item['id']"
    />
  </mx-select>
</template>

<script setup lang="ts">
import { ref, watch, computed, nextTick } from "vue";
import { list as fetchCombinerBoxList } from "@/api/combinerBox";
import { useRoute } from "vue-router";

const route = useRoute();
const props = defineProps({
  stationId: {
    type: Number,
    default: 0,
    require: true,
  },
  areaId: {
    type: Number,
    default: 0,
    require: true,
  },
  modelValue: {
    type: Number,
    default: null,
  },
  defaultValue: {
    type: Number,
    default: null,
  },
  autoSelectFirst: {
    type: Boolean,
    default: true,
  },
  width: {
    type: String,
    default: "158px",
  },
});
const emit = defineEmits(["update:modelValue", "itemChange"]);

const currentValue = computed<number>({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
});

const options = ref<any[]>([]);

const loading = ref(false);
watch(
  // @ts-ignore
  () => props.areaId,
  () => {
    if (!props.areaId) {
      emit("update:modelValue", null);
      return;
    }

    loading.value = true;
    fetchCombinerBoxList(props.stationId, props.areaId).then(list => {
      loading.value = false;
      options.value = list;
      if (
        route.query?.combinerBoxId &&
        list.filter((item: any) => {
          return item.id == route.query?.combinerBoxId;
        }).length
      ) {
        const queryId = Number(route.query?.combinerBoxId);
        emit("update:modelValue", queryId);
        return;
      }
      // 保持先前的值
      if (props.modelValue && list.map(d => d.id).includes(props.modelValue)) {
        return;
      } else {
        // 需干掉先前的值
        emit("update:modelValue", null);
      }

      // 默认值
      if (
        props.defaultValue &&
        list.map(d => d.id).includes(props.defaultValue)
      ) {
        emit("update:modelValue", props.defaultValue);
        return;
      }

      if (props.autoSelectFirst && list.length > 0) {
        emit("update:modelValue", list[0]["id"]);
      }
    });
  },
  { immediate: true },
);

const refSelect = ref(null);
watch(
  () => props.modelValue,
  () => {
    nextTick(() => {
      // @ts-ignore
      emit("itemChange", refSelect.value.selected);
    });
  },
);
</script>
