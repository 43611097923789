<template>
  <XToolbar>
    <template #body>
      <el-form :inline="true" class="filter-form ptb10">
        <el-form-item label="选择日期：">
          <XDateRangePicker v-model="query.dateRange" mode="hour" />
        </el-form-item>
      </el-form>
    </template>
    <template #right>
      <div class="flex gap-8">
        <slot name="export" :query="query"></slot>
        <slot name="link-btn" :query="query"></slot>
      </div>
    </template>
  </XToolbar>
  <slot name="chart" :query="query"></slot>
</template>

<script setup lang="ts">
import { reactive, watch } from "vue";
import useQueryStore from "@/store/query";

const queryStore = useQueryStore();

const props = defineProps({
  cacheKey: {
    type: String,
    require: true,
    default: null,
  },
  query: {
    type: Object,
    default: () => {},
  },
});

type Query = {
  dateRange: [string, string];
};
const defs = queryStore.get(props.cacheKey) as Query;
const query = reactive<Query>({ dateRange: [""] as any });
if (props.query && props.query.dateType) {
  query.dateRange = props.query.dateRange;
} else if (defs && defs.dateRange) {
  query.dateRange = defs.dateRange;
}
watch(
  () => query.dateRange,
  () => {
    queryStore.set(props.cacheKey, query);
  },
);
</script>

<style lang="scss" scoped>
.toolbar {
  display: flex;
  .main {
    flex: 1;
  }
  .right {
    text-align: right;
  }
}
</style>
