<template>
  <template v-if="props.mode === 'hour'">
    <mx-date-picker
      v-model="currentValue"
      :clearable="false"
      :disabled-date="disabledDateRules.hour"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      style="width: 246px"
    >
    </mx-date-picker>
  </template>
  <template v-if="props.mode === 'day'">
    <mx-date-picker
      v-model="currentValue"
      :clearable="false"
      :disabled-date="disabledDateRules.day"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      style="width: 246px"
    >
    </mx-date-picker>
  </template>
  <template v-if="props.mode === 'month'">
    <mx-date-picker
      v-model="currentValue"
      :clearable="false"
      :disabled-date="disabledDateRules.month"
      type="monthrange"
      unlink-panels
      range-separator="至"
      start-placeholder="开始月份"
      end-placeholder="结束月份"
      style="width: 246px"
    >
    </mx-date-picker>
  </template>
</template>

<script setup lang="ts">
import { computed, watch, onMounted } from "vue";
import dayjs from "dayjs";
import { useRoute } from "vue-router";
import { MxMessage } from "@maxtropy/v-components";
const route = useRoute();
const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  mode: {
    type: String,
    default: "hour",
  },
  month: {
    type: [Number, String],
    default: 2,
  },
});

const todayObj = dayjs(dayjs().format("YYYY-MM-DD 00:00:00"));
const monthObj = dayjs(todayObj.format("YYYY-MM-01"));
const disabledDateRules = {
  hour: (date: any) => {
    const cellDate = dayjs(date);
    if (cellDate.isAfter(todayObj)) return true;
    // if (cellDate.isBefore(todayObj.subtract(10, "day"))) return true;
    return false;
  },
  day: (date: any) => {
    const cellDate = dayjs(date);
    if (cellDate.isAfter(todayObj)) return true;
    // if (cellDate.isBefore(todayObj.subtract(1, "month"))) return true;
    return false;
  },
  month: (date: any) => {
    const cellDate = dayjs(date);
    if (cellDate.isAfter(monthObj)) return true;
    // if (cellDate.isBefore(monthObj.subtract(2, "year"))) return true;
    return false;
  },
};

const defaultValueRules = {
  hour: [todayObj.subtract(6, "day"), todayObj],
  day: [todayObj.subtract(1, "month").add(1, "day"), todayObj], // 得再加1天，不然多出1天
  month: [monthObj.subtract(props.month, "year").add(1, "day"), monthObj], // 得再加1天，不然多出1天
};

const currentValue = computed({
  get: () => props.modelValue,
  set: (val: any[]) => {
    if (props.mode === "hour" && dayjs(val[1]).diff(dayjs(val[0]), "day") > 6) {
      return MxMessage.error("跨度不能大于7天");
    }
    if (props.mode === "day" && dayjs(val[1]).diff(dayjs(val[0]), "day") > 30) {
      return MxMessage.error("跨度不能大于30天");
    }
    if (
      props.mode === "month" &&
      dayjs(val[1]).diff(dayjs(val[0]), "month") > 12 * props.month
    ) {
      return MxMessage.error(`跨度不能大于${12 * props.month}个月`);
    }
    if (val && val.length === 2) {
      val = val.map(date => dayjs(date).format("YYYY-MM-DD HH:mm:ss"));
    }
    emit("update:modelValue", val);
  },
});

onMounted(() => {
  if (route.query?.dateRange) {
    emit("update:modelValue", [route.query?.dateRange, route.query?.dateRange]);
    return;
  }
  if (
    !props.modelValue ||
    props.modelValue.length !== 2 ||
    props.modelValue[0] === ""
  ) {
    // @ts-ignore
    emit("update:modelValue", defaultValueRules[props.mode]);
  }
});

watch(
  () => props.mode,
  () => {
    // @ts-ignore
    emit("update:modelValue", defaultValueRules[props.mode]);
  },
);
</script>
