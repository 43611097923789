import { createApp, h } from "vue";
import router from "./router/index";
import App from "./App.vue";
import { createPinia } from "pinia";
import config from "@/utils/config";
import { plugin } from "echarts-for-vue";
import * as echarts from "echarts";
import ownDirective from "@/directives/own";
import sizeOb from "@/directives/size-ob";
import screenZoom from "@/utils/screen";
import piniaPluginPersist from "pinia-plugin-persist";
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import MaxtropyUI, { microAppRouterHandle } from "@maxtropy/v-components";
import "@maxtropy/v-components/dist/style.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

// import 'default-passive-events'
import "@/assets/iconfonts/iconfont.css";
import "./styles/base.scss";
import "./styles/variables.scss";
import "./styles/element.scss";
import "./styles/layout.scss";
import "./styles/public.scss";

// 处理登录后直接跳转到上次的页面
const loginCallbackUrl = localStorage.getItem("loginCallbackUrl");
if (loginCallbackUrl) {
  localStorage.removeItem("loginCallbackUrl");
  window.location.replace(loginCallbackUrl);
}

console.log("环境变量", import.meta.env);

config(import.meta.env.MODE).then(config => {
  // @ts-ignore
  window.$config = config;
  // @ts-ignore
  window.$version = "v1.7.25";
  // 初始化高德地图安全配置
  // @ts-ignore
  window._AMapSecurityConfig = { securityJsCode: config.amp.securityJsCode };

  // 屏幕缩放(兼顾小尺寸屏幕)
  screenZoom();

  // 空闲时长支持
  window.addEventListener(
    "mousemove",
    () => {
      // @ts-ignore
      window.$nextEventTime = new Date().getTime();
    },
    true,
  );

  // 读取完配置过后再启动
  const pinia = createPinia();
  pinia.use(piniaPluginPersist);
  const app = createApp(App);

  app
    .use(router)
    .use(pinia)
    .use(ElementPlus, { locale: zhCn })
    .use(MaxtropyUI)
    .use(plugin, { echarts, h })
    .directive("own", ownDirective)
    .directive("size-ob", sizeOb)
    .mount("#app");
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
  }
});
